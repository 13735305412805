import React, { useState } from 'react';
import '../styles/UserRegistration.css';

const UserRegistration = () => {
  const [formData, setFormData] = useState({
    nombre: '',
    email: '',
    mensaje: '', // Nuevo campo para el mensaje
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch('http://localhost:9000/register', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        console.log('Formulario enviado correctamente');
        setFormData({ nombre: '', email: '', mensaje: '' }); // Limpia el formulario
        // Aquí podrías agregar lógica adicional para mostrar un mensaje de éxito al usuario
      } else {
        console.log('Error al enviar el formulario');
        // Aquí podrías agregar lógica adicional para mostrar un mensaje de error al usuario
      }
    } catch (error) {
      console.error('Error al enviar el formulario:', error);
      // Aquí podrías agregar lógica adicional para mostrar un mensaje de error al usuario
    }
  };

  return (
    <div className="registration-form-container">
      <h2>Contact Form</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="nombre">Nombre:</label>
          <input
            type="text"
            id="nombre"
            name="nombre"
            value={formData.nombre}
            onChange={handleChange}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="mensaje">Mensaje (opcional):</label>
          <textarea
            id="mensaje"
            name="mensaje"
            value={formData.mensaje}
            onChange={handleChange}
            maxLength={4000}
          />
        </div>

        <div className="form-group">
          <button type="submit">Enviar</button>
        </div>
      </form>
    </div>
  );
};

export default UserRegistration;
