import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import '../App.css';
import '../index.css';
import '../styles/HomePage.css';
import './i18n'; // Ensure this is correctly set up for internationalization

const HomePage = () => {
  const { t } = useTranslation();

  return (
    <div className="wedogis-home">
      {/* Welcome Title */}
      <h1>{t('welcome_title')}</h1>

      {/* Introduction Section */}
      <p>{t('introduction')}</p>

      {/* Expertise Section */}
      <section>
        <h2>{t('expertise_title')}</h2>
        <ul>
          <li>
            <p>
              <Link to="/portfolio#urban-planning">
                <strong>{t('urban_planning_title')}</strong>: {t('urban_planning_desc')}
              </Link>
            </p>
          </li>
          <li>
            <p>
              <Link to="/portfolio#database-management">
                <strong>{t('database_management_title')}</strong>: {t('database_management_desc')}
              </Link>
            </p>
          </li>
          <li>
            <p>
              <Link to="/portfolio#web-viewers">
                <strong>{t('web_viewers_title')}</strong>: {t('web_viewers_desc')}
              </Link>
            </p>
          </li>
          <li>
            <p>
              <Link to="/portfolio#gmao-systems">
                <strong>{t('gmao_systems_title')}</strong>: {t('gmao_systems_desc')}
              </Link>
            </p>
          </li>
          <li>
            <p>
              <Link to="/portfolio#fleet-tracking">
                <strong>{t('fleet_tracking_title')}</strong>: {t('fleet_tracking_desc')}
              </Link>
            </p>
          </li>
        </ul>
      </section>

      {/* Commitment Section */}
      <section>
        <h3>{t('commitment_title')}</h3>
        <p>{t('commitment_desc')}</p>
      </section>

      {/* Additional Content Sections */}
      {/* Here you can add more sections as needed for your homepage */}
    </div>
  );
}

export default HomePage;
