import React from 'react';
import './App.css';
import Navigation from './components/Navbar';
import Portfolio from './components/Portfolio';
import UserRegistration from './components/UserRegistration';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './components/HomePage';
// import ArticlePolygons from './components/ArticlePolygons';

function App() {
  return (
    <Router>
      <div className="App">
        <Navigation />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/register" element={<UserRegistration />} />
          {/* <Route path="/articles" element={<ArticlePolygons />} /> */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;



