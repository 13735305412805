import React from 'react';
import YouTube from 'react-youtube';
import '../styles/Portfolio.css';

const Portfolio = () => {
  const opts = {
    height: '400',
    width: '620',
    playerVars: {
      autoplay: 0,
    },
  };

  const videos = [
    { 
      id: 'FfOpA-uBQL8',
      title: 'Gestion de mantenimiento por ordenador.GMAO', 
      description: (
        <div>
          
          <p>Se trata del desarrollo de aplicaciones web utilizando la pila PERN 💻 (PostgreSQL, Express, React y Node.js).</p>
          <p>El enfoque principal se centra en la creación de aplicaciones web robustas y escalables.</p>
          <p>React se utiliza para la interfaz de usuario, mientras que Express/Node.js se encarga del backend.</p>
          <p>Este logro fue posible gracias a un sólido conocimiento en la gestión de bases de datos PostgreSQL y al uso de Git para el control de versiones, lo cual aseguró un seguimiento preciso del código y una colaboración eficiente en equipo.</p>
        </div>
      )
    },
    
    
    { id: '1QrfraetDSo', 
    title: 'Uso de Google API', 
    description: <p>fundamentos para el uso de Google API  💡.</p> },
    // { id: '_7KPBYbKlwY', title: 'Video Título 3', description: <p>.</p> },
    { id: 'N-gEts4_sPs', title: 'Mapeo. Visores web. Rstudio', description: <p>Manejo de KML y KMZ en Rstudio 💡.</p> },
    // Puedes seguir añadiendo más videos aquí.
  ];

  // Agregamos la presentación y el enlace antes del bucle de videos
  return (
    <div className="portfolio-container">
      <h2>Portfolio</h2>
      {/* Agregamos la presentación y el enlace aquí */}
      <div className="portfolio-introduction">
        <p>
          Soy Mab Palavecino.Ingeniera. Full Stack dev y DBA apasionada por la creación de soluciones tecnológicas innovadoras y eficientes. Mi experiencia abarca un amplio espectro, desde el desarrollo de herramientas para el manejo de bases de datos geoespaciales hasta la creación de potentes aplicaciones web y sistemas de gestión de mantenimiento asistido por computadora (GMAOs).
        </p>
        {/* Reemplaza "URL_de_Tu_Foto" y "URL_de_Tu_Perfil_de_LinkedIn" con tus enlaces reales */}
        <img src="https://media.licdn.com/dms/image/D4E03AQGB4UBXqN5EVw/profile-displayphoto-shrink_800_800/0/1672173704639?e=1709769600&v=beta&t=F4TthDDCU9xHFWhQMDCK1PbKFMJBxEdIDIi1LfHHZlI" alt="Mi Foto" />
        <p>
          ¡no dudes en visitar mi perfil de LinkedIn!
        </p>
        <a href="https://www.linkedin.com/in/mab-palavecino-06898025b/" target="_blank" rel="noopener noreferrer">Ver mi perfil de LinkedIn</a>
      </div>

      {videos.map((video, index) => (
        <div key={index} className="video-section">
          <div className="video-container">
            <YouTube videoId={video.id} opts={opts} />
          </div>
          <div className="video-info">
            <h3>{video.title}</h3>
            <p>{video.description}</p>
          </div>
        </div>
      ))}
    </div>
  );
}

export default Portfolio;
