import React from 'react';
import { NavLink } from 'react-router-dom';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import '../index.css';
import '../styles/Navbar.css';

const Navigation = () => {
  const { t, i18n } = useTranslation();

  const handleLanguageChange = (lang) => {
    i18n.changeLanguage(lang);
  };

  return (
    <Navbar bg="dark" variant="dark" expand="lg">
      <Navbar.Brand as={NavLink} exact to="/">
        <h1>WeDoGIS!</h1>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ml-auto">
          <Nav.Item>
            <NavLink exact to="/" className="nav-link">{t('nav_home')}</NavLink>
          </Nav.Item>
          <Nav.Item>
            <NavLink to="/portfolio" className="nav-link">{t('nav_portfolio')}</NavLink>
          </Nav.Item>
          {/* <Nav.Item>
            <NavLink to="/articles" className="nav-link">{t('nav_articles')}</NavLink>
          </Nav.Item> */}
          <Nav.Item>
            <NavLink to="/register" className="nav-link">{t('nav_register')}</NavLink>
          </Nav.Item>

          <NavDropdown title={t('nav_language')} id="language-dropdown">
            <NavDropdown.Item onClick={() => handleLanguageChange('en')}>English</NavDropdown.Item>
            <NavDropdown.Item onClick={() => handleLanguageChange('es')}>Español</NavDropdown.Item>
          </NavDropdown>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default Navigation;
